<template>
  <div class="qingwu" v-loading="loading">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>商家联盟</el-breadcrumb-item>
          <el-breadcrumb-item>修改商家信息</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <el-form
        label-position="top"
        :model="merchantDetail"
        :rules="rules"
        ref="form"
      >
        <el-row :gutter="40">
          <el-col :span="6">
            <el-form-item label="商户类型" prop="merchantType">
              <el-select
                v-model="merchantDetail.merchantType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in merchantType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="公司名称" prop="companyName">
              <el-input
                placeholder="请输入公司名称"
                v-model="merchantDetail.companyName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="营业执照代码" prop="businessLicenseCode">
              <el-input
                placeholder="请输入营业执照代码"
                v-model="merchantDetail.businessLicenseCode"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="6">
            <el-form-item label="开户银行名称" prop="bankId">
              <el-select
                v-model="merchantDetail.bankId"
                filterable
                placeholder="请选择"
                @change="handleBankChange"
              >
                <el-option
                  v-for="item in bankNames"
                  :key="item.bankId"
                  :label="item.bankName"
                  :value="item.bankId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="开户省市" prop="bankOpenArea">
              <el-select
                v-model="merchantDetail.bankOpenArea"
                filterable
                placeholder="请选择"
                @change="handleBankAreaChange"
              >
                <el-option
                  v-for="item in areaList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="开户支行名称" prop="openBankName">
              <el-select
                v-model="merchantDetail.openBankName"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in subBankList"
                  :key="item.id"
                  :label="item.subBranchName"
                  :value="item.subBranchName"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <div style="text-align: right">
          <el-button
            @click="
              $router.replace({ path: '/Admin/merchant/merchant/merchantlist' })
            "
            >返回商家列表</el-button
          >
          <el-button type="primary" @click="handleSubmit">保存修改</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "merchantUpdate",
  data() {
    return {
      loading: true,
      userInfo: {},
      merchantDetail: {},
      merchantType: [
        {
          label: "个体户",
          value: 1,
        },
        {
          label: "企业商户",
          value: 2,
        },
        {
          label: "个人（小微）",
          value: 3,
        },
      ],
      bankNames: [],
      areaList: [],
      subBankList: [],
      rules: {
        merchantType: [{ required: true, message: "请选择商户类型" }],
        companyName: [{ required: true, message: "请输入公司名称" }],
        // businessLicenseCode: [
        //   { required: true, message: "请输入营业执照代码" },
        // ],
        bankId: [{ required: true, message: "请选择开户银行名称" }],
        bankOpenArea: [{ required: true, message: "请选择开户省市" }],
        openBankName: [{ required: true, message: "请选择开户银行地址" }],
      },
    };
  },
  created() {
    this.getMerchantDetail();
    this.getBankNames();
    this.getUserInfo();
  },
  methods: {
    /**
     * @deprecated 获取银行数据
     */
    async getBankNames() {
      const { code, data } = await this.$get(this.$api.getBankNames);
      if (code === 1) {
        this.bankNames = data;
      }
    },
    /**
     * @description 获取开户省市数据
     */
    async getAreaList(bankId) {
      const { code, data } = await this.$get(
        `${this.$api.getBankProvinceList}/${bankId}`
      );
      if (code === 1) {
        const newAreaList = [];
        data.forEach((province) => {
          const provinceName = province.name;
          if (province.children && province.children.length) {
            province.children.forEach((city) => {
              newAreaList.push({
                label: `${provinceName}-${city.name}`,
                value: city.cityId,
                provinceName,
                cityName: city.name,
              });
            });
          }
        });
        this.areaList = newAreaList;
        // 获取到开户省市列表后获取当前开户省市ID
        const currentCity = this.getCurrentCity(
          this.merchantDetail.bankOpenArea
        );
        const getCurrentBank = this.getCurrentBank(this.merchantDetail.bankId);
        // 通过当前开户省市ID获取支行信息
        this.getBankByCityId(
          getCurrentBank.bankName,
          currentCity?.provinceName,
          currentCity?.cityName
        );
      }
    },
    /**
     * @description 获取开户省市数据
     */
    async getMerchantDetail() {
      const { code, data } = await this.$get(
        `${this.$api.merchantDetail}/${this.$route.query.id}`
      );
      if (code === 1) {
        this.merchantDetail = data;
        // 获取到商家详情后通过bankId获取开户省市
        this.getAreaList(data.bankId);
      }
      this.loading = false;
    },
    /**
     * @deprecated 获取支行信息
     */
    async getBankByCityId(bankName, provinceName, cityName) {
      const { code, data } = await this.$post(this.$api.getBankByCityId, {
        bankName: bankName,
        provinceName: provinceName,
        cityName: cityName,
      });
      if (code === 1) {
        this.subBankList = data;
      }
    },
    /**
     * @deprecated 通过城市名获取城市信息
     */
    getCurrentCity(cityName) {
      return this.areaList.find(({ label }) => label === cityName);
    },
    /**
     * @deprecated 通过银行ID获取银行名称
     */
    getCurrentBank(id) {
      return this.bankNames.find(({ bankId }) => bankId === id);
    },
    /**
     * @deprecated 通过支行名称获取支行信息
     */
    getCurrentSubBand(subBankName) {
      return this.subBankList.find(
        ({ subBranchName }) => subBranchName === subBankName
      );
    },
    /**
     * @deprecated 获取登录用户信息
     */
    getUserInfo() {
      this.userInfo = JSON.parse(localStorage.getItem("user_info") || {});
    },
    /**
     * @description 选择开户银行名称时触发事件
     */
    handleBankChange(bankId) {
      // 清空开户省市以及开户支行选项
      this.merchantDetail.bankOpenArea = "";
      this.merchantDetail.openBankName = "";
      this.getAreaList(bankId);
    },
    /**
     * @description 选择开户省市时触发事件
     */
    handleBankAreaChange(cityName) {
      // 清空开户支行选项
      this.merchantDetail.openBankName = "";
      const currentCity = this.getCurrentCity(cityName);
      const getCurrentBank = this.getCurrentBank(this.merchantDetail.bankId);
      this.getBankByCityId(
        getCurrentBank.bankName,
        currentCity?.provinceName,
        currentCity?.cityName
      );
    },
    /**
     * @deprecated 提交
     */
    handleSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const {
            merchantType,
            companyName,
            businessLicenseCode,
            bankName,
            bankOpenArea,
            openBankName,
          } = this.merchantDetail;
          const subBank = this.getCurrentSubBand(openBankName);
          const { code, msg } = await this.$post(this.$api.updateMerchant, {
            merchantType,
            companyName,
            businessLicenseCode,
            bankName,
            bankOpenArea,
            bankAddress: subBank?.subBranchName,
            branchNo: subBank?.subBranchId,
            updateAccount: this.userInfo.account,
            id: this.$route.query.id,
          });
          if (code === 1) {
            this.$message.success("修改成功");
            this.getMerchantDetail();
          } else {
            this.$message.error(msg);
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
